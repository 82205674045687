// i18next-extract-mark-ns-start capabilities-thermal-fe

import { LINKS, THERMAL_NAV, CAPABILITIES_PRODUCTS_FE_THERMAL_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesFEThermalPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);
	const vulcan = getImage(data.vulcan.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							Many Original Equipment Manufacturers (OEMs) already have methods in place for conducting thermal analysis
							and predicting the heat effects on mechanical structures and components.
							However, they are often time consuming, costly, and less efficient for today’s modern engines.
							Predictive methods are essential to successfully understand the thermal behaviour early in the design phase and
							throughout the development process, as physical testing alone cannot be relied upon to guide the design.
							But how can this be achieved in practice?
						</p>
						<p>
							Realis have developed a new reliable, accurate and predictive tool that can be used easily with limited data,
							and in timescales appropriate for real engineering development.
							It is called FEARCE-Vulcan, thermal Finite Element (FE) analysis for Internal Combustion Engines (ICE).
						</p>
						<ul>
							<li>Delivers a robust process for optimising cylinder components quickly with minimal inputs.</li>
							<li>Accurate thermal predictions for vehicle thermal management and combustion performance, enabling designs for lower emissions.</li>
							<li>Unique iterative solution that considers all heat paths giving the detailed structural analysis required for improved structural integrity.</li>
							<li>Fast model set up and reduced simulation times with finite element tools for durability analysis and thermal load modelling.</li>
						</ul>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="3-Cylinder Temperatures" />
							<Trans i18nKey="small"><small>Predicted metal temperature for a 3-cylinder gasoline DI engine</small></Trans>
						</p>
					)}

					<Trans i18nKey="content_2">
						<p>
							FEARCE-Vulcan is complementary to existing Computational Fluids Dynamics (CFD) analysis, provided by VECTIS, and thermal survey processes,
							and requires as input cylinder pressure data from measurement or from 1D CFD performance simulation using WAVE.
						</p>
					</Trans>

					{vulcan && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vulcan}
								alt="FEARCE-Vulcan"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							FEARCE-Vulcan uses a unique iterative Finite Element solution that considers all the heat paths in detail to deliver a reliable
							and accurate predictive tool for conclusive structural investigations.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_4">
						<p>
							VECTIS is a 3D CFD tool that uses cutting edge kinetics, flame propagation and emissions models
							for conventional hydrocarbons, e-fuels and synthetic fuels including hydrogen.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_5">
						<p>
							WAVE is a 1D CFD tool that solves the Navier-Stokes equations governing the transfer of mass,
							momentum, and energy for compressible gas flows, and includes sub-models for combustion and emissions.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_ThermalEfficiency")}
						className="widget__sibling__pages">
						<AssetList>
							{THERMAL_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_FE_THERMAL_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default CapabilitiesFEThermalPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-thermal-fe", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/thermal/Realis-Simulation-Thermal.jpg" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/capabilities/thermal/Realis-Simulation-ICE-3-Cylinder-Temperatures.png" }) {
		...imageStandard
	}
	vulcan: file(relativePath: { eq: "content/rs_fearce_vulcan.png" }) {
		...imageBreaker
	}
	wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
		...imageBreaker
	}
	vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
}
`;
